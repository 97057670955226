import { Box } from "@mui/material";
import React from "react";
import { YesIcon } from "./YesIcon";
import { NoIcon } from "./NoIcon";
import { ANSWER_NOT_APPLICABLE } from "../../../common/constants/caliberAnswers";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";

export const RenderAnswerView = ({ type }) => {
  const backgroundColor =
    type === "yes" || type == ANSWER_NOT_APPLICABLE ? "#FFF6E4" : "#DFDFDF";
  const color =
    type === "yes" || type == ANSWER_NOT_APPLICABLE ? "#F5961E" : "#92929D";
  return (
    <Box
      sx={{
        width: 72,
        height: 40,
        backgroundColor: backgroundColor,
        border: "1px solid",
        borderColor: color,
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 8,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ fontFamily: "Effra", color }}>
          {(type == "yes" && "نعم") ||
            (type == "no" && "لا") ||
            (type == ANSWER_NOT_APPLICABLE && "لا ينطبق") ||
            (type == "invalid" && "لا ينطبق")}
        </span>
        {type === "yes" && <YesIcon fill={color} />}
        {type === "no" && <NoIcon fill={color} />}
      </div>
    </Box>
  );
};
